const landingPageBuilder = {

    /**
     * Settings
     */
    slideSpeed: 200,

    /**
     * Initialize the landing page builder
     */
    init: function() {

        const self = this;

        // Identify elements
        self.identifyElements();

        // Grab block template HTML, then remove that element from the DOM
        self.blockTemplate = self.$blockTemplate.html();
        self.$blockTemplate.remove();

        // Load the current blocks from the JSON input
        self.loadBlocksFromJson();

        // Toggle dependent fields
        self.setDependentFieldsVisibility()

        // Listen for file uploads
        self.handleFileUploads();

    },

    handleFileUploads: function() {
        const self = this;

        self.$blockWrapper.on('change', '.js-landing-page-block-field-image', function() {
            const input = this;

            const $block = $(input).closest('.js-landing-page-block');

            const formData = new FormData();
            formData.append('file', input.files[0]);

            $.ajax({
                url: '/landing-page/upload-image',
                type: 'POST',
                processData: false,
                contentType: false,
                data: formData,
                headers: {
                    'X-CSRF-TOKEN': $('meta[name="csrf-token"]').attr('content')
                },
            }).done(function(response) {
                console.log(response);

                $block.find('.js-landing-page-block-field-image-id').val(response.id);
                $block.find('.js-landing-page-block-field-image-url').val(response.url);
                $block.find('.js-landing-page-block-image-preview')
                    .attr('src', response.url)
                    .show();

                self.updateJson();
            });
        });
    },

    /**
     * Identify elements
     */
    identifyElements: function() {

        const self = this;

        self.$builder = $('.landing-page-builder').first();
        self.$addButton = $('.js-landing-page-add-block').first();
        self.$blockWrapper = $('.js-landing-page-blocks').first();
        self.$blocks = $('.js-landing-page-block');
        self.$dragButtons = $('.js-landing-page-block-drag');
        self.$toggleButtons = $('.js-landing-page-block-toggle');
        self.$removeButtons = $('.js-landing-page-block-remove');
        self.$blockFields = $('.js-landing-page-block-field');
        self.$blockTemplate = $('.js-landing-page-block-template').first();
        self.$jsonInput = $('.js-landing-page-json').first();

        // Run these actions to update the elements
        self.setUpListeners();

    },

    /**
     * Set up listeners for button clicks, changes, etc.
     */
    setUpListeners: function() {

        const self = this;

        // Remove any existing click events
        self.$addButton.off('click');
        self.$removeButtons.off('click');
        self.$toggleButtons.off('click');
        self.$blockFields.off('change');
        self.$dragButtons.off('mousedown');
        $(document).off('mousemove');
        $(document).off('mouseup');

        // 'Add' button click
        self.$addButton.on('click', function() {
            self.addBlock();
        });

        // 'Remove' button click
        self.$removeButtons.on('click', function() {
            self.removeBlock( $(this).closest('.js-landing-page-block') );
        });

        // 'Open/close' button click
        self.$toggleButtons.on('click', function() {
            self.toggleBlockVisibility( $(this).closest('.js-landing-page-block') );
        });

        // Drag button mouse down
        self.$dragButtons.on('mousedown', function( event ) {
            self.startDragging( event, $(this).closest('.js-landing-page-block') );
        });

        // Drag button mouse up
        $(document).on('mouseup', function() {
            self.endDragging();
        });

        // Text field changes
        self.$blockFields.on('change', function() {
            const block = $(this).closest('.js-landing-page-block');
            self.updateFieldVisibilityForBlock(block);
            self.updateJson();
        });

    },

    setDependentFieldsVisibility: function() {
        $('.js-landing-page-block').each((i, el) => {
            this.updateFieldVisibilityForBlock($(el));
        });
    },

    updateFieldVisibilityForBlock: function($block) {
        const self = this;

        $block.find(`[data-dependent-rules]`).each((i, el) => {
            self.updateDependentFieldVisibility(el);
        });
    },

    updateDependentFieldVisibility: function(field, animate = true) {

        const $dependentField = $(field);
        const $block = $dependentField.closest('.js-landing-page-block');
        const dependentFieldName = $dependentField.find('[data-block-field]').first().attr('data-block-field');

        // Get the visibility rules of this field
        const rules = $dependentField
            .attr('data-dependent-rules')
            .split('|')
            .map(rule => {
                let [field, values] = rule.split(':');
                values = values.split(',');
                return { field, values };
            })

        // Skip if we dont have any relevant rules for this field
        if (!rules.length) return;
        
        let passedCount = 0;

        // console.log(`Checking ${dependentFieldName}...`);

        // Check through each rule to make sure we have a match
        rules.forEach((rule, i) => {

            const $fieldToCheck = $block.find(`[data-block-field="${rule.field}"]`);
            let valueToCheck = $fieldToCheck.val();

            // Handle radio/checkbox values
            if ($fieldToCheck.attr('type') === 'radio' || $fieldToCheck.attr('type') === 'checkbox') {
                valueToCheck = $fieldToCheck.filter(':checked').length ? $fieldToCheck.filter(':checked').val() : '';
            }
            
            // console.log(`Rule #${i + 1}: field '${rule.field}' needs [${rule.values.join(',')}], has value '${valueToCheck}'`);

            if (rule.values.includes(valueToCheck)) {
                passedCount++;
            }
        });

        // console.log(`Passed: `, passedCount + '/' + rules.length);

        // Show the field if all rules pass
        if (passedCount === rules.length) {
            if (animate) {
                $dependentField.slideDown(this.slideSpeed);
            } else {
                $dependentField.show();
            }

            // Make the required fields required
            $dependentField.find('.is-required').prop('required', true);
        } else {
            if (animate) {
                $dependentField.slideUp(this.slideSpeed);
            } else {
                $dependentField.hide();
            }

            // Make the required fields not required
            $dependentField.find('.is-required').prop('required', false);
        }
    },

    /**
     * Add a block
     */
    addBlock: function() {

        const self = this;

        // Add block to the DOM
        self.$blockWrapper.append(self.blockTemplate);

        // Identify the new block
        const $newBlock = self.$blockWrapper.find('.js-landing-page-block').last();
        const newBlockNumber = self.$blockWrapper.find('.js-landing-page-block').length

        // Loop through fields
        $newBlock.find('.js-landing-page-block-field').each(function() {
            const $field = $(this);
            const key = $field.attr('data-block-field');
            switch ( $field.attr('type') ) {
                case 'radio':
                case 'checkbox':
                    // Give the field a name to group radio buttons / checkboxes
                    $field.attr('name', 'block-' + newBlockNumber + '-' + key);
                break;
            }
        });

        // Re-identify elements
        self.identifyElements();

        // Update json
        self.updateJson();

        return $newBlock;

    },

    /**
     * Remove a block
     */
    removeBlock: function( block ) {

        const self = this;

        // Remove the block from the DOM
        block.remove();

        // Re-identify elements
        self.identifyElements();

        // Update json
        self.updateJson();

    },

    /**
     * Toggle block open
     */
    toggleBlockVisibility: function( $block ) {

        const self = this;

        // Identify body
        const $body = $block.find('.js-landing-page-block-body');

        // Is block already closed?
        if ( $block.hasClass('is-closed') ) {

            // Remove block class
            $block.removeClass('is-closed');

            // Show the body
            $body.slideDown(self.slideSpeed);

            // Update the field
            $body.find('.js-landing-page-block-field-closed').val(0);

        } else {

            // Add block class
            $block.addClass('is-closed');

            // Hide the body
            $body.slideUp(self.slideSpeed);

            // Update the field
            $body.find('.js-landing-page-block-field-closed').val(1);
        
        }

        self.updateJson();
    
    },

    /**
     * Start dragging a block
     */
    startDragging: function( event, $block ) {

        const self = this;

        // Is being dragged already?
        if ( $block.hasClass('is-dragging') ) {
            return;
        }

        // Add the dragging class to the block
        $block.addClass('is-dragging');

        // Get block's starting position
        const blockStartY = $block.position().top;

        // Loop through all the other blocks to build an array of
        // their top positions relative to the current block
        const blockPositions = [];
        self.$blocks.each(function() {
            const $thisBlock = $(this);
            const blockPosition = {
                top: $thisBlock.position().top - blockStartY,
            };
            blockPositions.push(blockPosition);
        });

        // Get mouse's starting position on the vertical axis
        const mouseStartY = event.clientY;

        // Add a mouse move listener
        $(document).on('mousemove', function( event ) {

            // Get the mouse's current position on the vertical axis
            const mouseY = event.clientY;

            // Calculate the difference between the mouse's starting position and current position
            const difference = mouseY - mouseStartY;

            // Set the block's top position to the difference (using relative positioning)
            $block.css('top', difference);

            // Is the block moving up or down?
            if ( difference > 0 ) {

                // Loop through all the other blocks to see if the current block has passed any
                for ( let i = 0; i < blockPositions.length; i++ ) {

                    const $potentialTarget = self.$blocks.eq(i);

                    // Skip the current block
                    if ( $potentialTarget.is($block) ) {
                        continue;
                    }

                    if ( blockPositions[i].top > 0 && difference > blockPositions[i].top ) {

                        // Add the target class to the potential target block
                        $potentialTarget.addClass('is-target').addClass('is-target-down');

                        // Remove the target class from all other blocks
                        self.$blocks.not($potentialTarget).removeClass('is-target').removeClass('is-target-down');

                    } else {

                        // Remove the target class from the potential target block
                        $potentialTarget.removeClass('is-target').removeClass('is-target-down');

                    }

                }

            }  else if ( difference <= 0 ) {

                // Loop through the blocks, but this time in reverse
                for ( let i = blockPositions.length - 1; i >= 0; i-- ) {

                    const $potentialTarget = self.$blocks.eq(i);

                    // Skip the current block
                    if ( $potentialTarget.is($block) ) {
                        continue;
                    }

                    if ( blockPositions[i].top < 0 && difference < blockPositions[i].top ) {

                        // Add the target class to the potential target block
                        $potentialTarget.addClass('is-target').addClass('is-target-up');

                        // Remove the target class from all other blocks
                        self.$blocks.not($potentialTarget).removeClass('is-target').removeClass('is-target-up');

                    } else {

                        // Remove the target class from the potential target block
                        $potentialTarget.removeClass('is-target').removeClass('is-target-up');

                    }

                }

            }

        });

    },

    /**
     * Stop dragging a block
     */
    endDragging: function() {

        const self = this;

        // Find the block being dragged (if any)
        const $block = $('.js-landing-page-block.is-dragging');

        if ( $block.length ) {

            // Do any of the blocks have the target class?
            const $target = $('.js-landing-page-block.is-target').first();
            if ( $target.length ) {

                // Has target up or down class?
                if ( $target.hasClass('is-target-up') ) {

                    $target.before($block);

                } else if ( $target.hasClass('is-target-down') ) {

                    $target.after($block);

                }

                // Remove the target classes
                $target.removeClass('is-target').removeClass('is-target-up').removeClass('is-target-down');

                // Re-identify elements
                self.identifyElements();

                // Update json
                self.updateJson();

            }

            // Remove the dragging class and reset the top position
            $block.removeClass('is-dragging').css('top', 0);

            // Remove the mouse move listener
            $(document).off('mousemove');

        }

    },

    /**
     * Udpdate the JSON input
     */
    updateJson: function() {

        const self = this;

        // Create an array to hold the block data
        const blockData = [];

        // Loop through each block
        self.$blocks.each(function(index) {

            const $block = $(this);

            // Start the block data object
            const block = {};

            // Find all the fields within the block and loop through them
            const $fields = $block.find('.js-landing-page-block-field');
            $fields.each(function() {

                const $field = $(this);
                const fieldName = $field.attr('data-block-field');
                let fieldValue = '';

                // Get the field value (based on field type)
                switch ( $field.attr('type') ) {

                    case 'radio':
                    case 'checkbox':
                        const fieldName = $field.attr('name');
                        const $checkedField = $fields.filter(`[name="${fieldName}"]:checked`);
                        if ( $checkedField.length) {
                            fieldValue = $checkedField.val();
                        }
                    break;

                    default:
                        fieldValue = $field.val();
                    break;

                }

                // Add the field to the block data
                block[fieldName] = fieldValue;

            });

            // Push the block data to the array
            blockData.push(block);

        });

        // Convert the block data to JSON
        const json = JSON.stringify(blockData);

        // Update the JSON input value
        self.$jsonInput.val(json);

    },

    /**
     * Generate blocks from JSON input
     */
    loadBlocksFromJson: function() {

        // Get the JSON input value
        const json = this.$jsonInput.val();

        if ( json ) {

            // Decode the JSON
            const blocks = JSON.parse(json);

            // Loop the blocks
            for ( let i = 0; i < blocks.length; i++ ) {

                // Add a block
                const $newBlockEl = this.addBlock();

                // Get the block
                const $block = this.$blocks.eq(i);

                // Loop the block data to set the field values
                for ( const key in blocks[i] ) {

                    // Get the field
                    const $field = $block.find('.js-landing-page-block-field[data-block-field="' + key + '"]');

                    // Update the field value (based on field type)
                    switch ( $field.attr('type') ) {

                        case 'radio':
                        case 'checkbox':
                            if ( blocks[i][key] ) {
                                $field.filter('[value="' + blocks[i][key] + '"]').prop('checked', true);
                            }
                        break;
    
                        default:
                            $field.val(blocks[i][key]);
                        break;
    
                    }

                    // Update the visibility
                    if (key === 'is_closed' && blocks[i][key] == 1) {

                        // Add block class
                        $newBlockEl.addClass('is-closed');

                        // Slide up the body
                        $newBlockEl.find('.js-landing-page-block-body').slideUp(self.slideSpeed);
                    }

                    // Update the image preview
                    if (key === 'image_url' && blocks[i][key]) {
                        $newBlockEl.find('.js-landing-page-block-image-preview').attr('src', blocks[i][key]).show();
                    }
                }
            }
        }
    },
};

module.exports = landingPageBuilder;